/* You can add global styles to this file, and also import other style files */
:root {
  --fontFamily1: "Poppins", sans-serif;
  --fontFamily2: "Open Sans", sans-serif;
  --fontFamily3: "Playfair Display", serif;
  --mainColor: #ee315a;
  --mainColor2: #005d40;
  --optionalColor: #0eb582;
  --whiteColor: #ffffff;
  --blackColor: #123670 //#424242;
  --paragraphColor: #123670;
  --fontSize: 18px // 15px;
  --transition: 0.5s;
}

.--button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: #ee315a;
  font-weight: bold;
  text-transform: uppercase;
  outline: none;
  color: white;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}
.--button:hover {
  opacity: 85%;
}
.--button:active {
  opacity: 1;
}

.--button:disabled {
  opacity: 85%;
}

.--button.--secondary {
  background-color: #fff;
  color: #1c53ad !important;
}

.--button.--third {
  background-color: #1c53ad;
  color: #fff;
}

.--button.--third:hover {
  background-color: #123670;
  opacity: 1;
}

.--button.--secondary:hover {
  opacity: 1;
  background-color: #eee;
}

.--button.sm {
  padding: 0.3rem 0.5rem !important;
  font-size: 12px;
}

.--button.s2 {
  border-radius: 0;
}

.dnett-cursor {
  cursor: pointer !important;
}

body {
  background-color: var(--whiteColor);
  color: #123670;
  padding: 0;
  margin: 0;
  font-family: var(--fontFamily1);
  font-size: var(--fontSize);
}
body::-webkit-scrollbar {
  width: 8px;
  border-radius: 100%;
}
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
body::-webkit-scrollbar-thumb {
  background: #ee315a;
}
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  text-decoration: none;
  transition: var(--transition);
  color: var(--blackColor);
}
a:hover, a:focus {
  color: var(--mainColor);
  text-decoration: none;
}

input,
button,
a {
  outline: 0 !important;
}

p {
  margin-bottom: 15px;
  line-height: 1.8;
  color: var(--paragraphColor);
  font-family: var(--fontFamily2);
  font-size: var(--fontSize);
}
p:last-child {
  margin-bottom: 0;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.bg-f5faf8 {
  background-color: #f5faf8;
}

.bg-f8fafb {
  background-color: #f8fafb;
}

.bg-f9f9f9 {
  background-color: #f9f9f9;
}

.bg-e4feff {
  background-color: #e4feff;
}

.bg-f8e8e9 {
  background-color: #f8e8e9;
}

.bg-F7F9FB {
  background-color: #f7f9fb;
}

.bg-e6fbff {
  background-color: #e6fbff;
}

.divider.bg-f9f9f9 {
  background-color: #f9f9f9;
}
.divider.bg-e4feff {
  background-color: #e4feff;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--blackColor);
  font-family: var(--fontFamily1);
}
.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

.playfair-display {
  font-family: var(--fontFamily3);
}

.bg-370920 {
  background-color: #370920;
}

.bg-dark {
  background-color: #000000 !important;
  color: var(--whiteColor);
}
.bg-dark .h1,
.bg-dark .h2,
.bg-dark .h3,
.bg-dark .h4,
.bg-dark .h5,
.bg-dark .h6,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6 {
  color: var(--whiteColor);
}
.bg-dark p {
  color: #d4d4d4;
}
.bg-dark.bg-0b0517 {
  background-color: #0b0517 !important;
}
.bg-dark.bg-100f1f {
  background-color: #100f1f !important;
}
.bg-dark a {
  color: var(--whiteColor);
}
.bg-dark a:hover {
  color: var(--mainColor);
}
.bg-dark .default-btn {
  color: var(--whiteColor);
}
.bg-dark .default-btn:hover, .bg-dark .default-btn:focus {
  color: var(--whiteColor);
}
.bg-dark .single-blog-post-box .post-content .category {
  color: var(--mainColor) !important;
}
.bg-dark .single-blog-post-box .post-content .category:hover {
  color: var(--optionalColor) !important;
}

@font-face {
  font-family: "lg";
  src: url("../fonts/lg.woff2?io9a6k") format("woff2"), url("../fonts/lg.ttf?io9a6k") format("truetype"), url("../fonts/lg.woff?io9a6k") format("woff"), url("../fonts/lg.svg?io9a6k#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-container {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.lg-next,
.lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  outline: none;
  border: none;
}
.lg-next.disabled,
.lg-prev.disabled {
  opacity: 0 !important;
  cursor: default;
}
.lg-next:hover:not(.disabled),
.lg-prev:hover:not(.disabled) {
  color: #fff;
}
.lg-single-item .lg-next,
.lg-single-item .lg-prev {
  display: none;
}

.lg-next {
  right: 20px;
}
.lg-next:before {
  content: "\e095";
}

.lg-prev {
  left: 20px;
}
.lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}
.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}
.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  will-change: color;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  background: none;
  border: none;
  box-shadow: none;
}
.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 18px;
}
.lg-toolbar .lg-icon:hover {
  color: #fff;
}
.lg-toolbar .lg-close:after {
  content: "\e070";
}
.lg-toolbar .lg-maximize {
  font-size: 22px;
}
.lg-toolbar .lg-maximize:after {
  content: "\e90a";
}
.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  color: #eee;
  font-size: 16px;
  padding: 10px 40px;
  text-align: center;
  z-index: 1080;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out 0s;
  -o-transition: opacity 0.2s ease-out 0s;
  transition: opacity 0.2s ease-out 0s;
}
.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}
.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}
.lg-sub-html a {
  color: inherit;
}
.lg-sub-html a:hover {
  text-decoration: underline;
}
.lg-media-overlap .lg-sub-html {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}
.lg-item .lg-sub-html {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.lg-error-msg {
  font-size: 14px;
  color: #999;
}

.lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  height: 47px;
  vertical-align: middle;
}

.lg-closing .lg-toolbar,
.lg-closing .lg-prev,
.lg-closing .lg-next,
.lg-closing .lg-sub-html {
  opacity: 0;
  -webkit-transition: -webkit-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  -moz-transition: -moz-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  -o-transition: -o-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  transition: transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  -moz-transform: scale3d(0.5, 0.5, 0.5);
  -o-transform: scale3d(0.5, 0.5, 0.5);
  -ms-transform: scale3d(0.5, 0.5, 0.5);
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
}
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  -moz-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.lg-icon:focus-visible {
  color: #fff;
  border-radius: 3px;
  outline: 1px dashed rgba(255, 255, 255, 0.6);
}

.lg-toolbar .lg-icon:focus-visible {
  border-radius: 8px;
  outline-offset: -5px;
}

.lg-group:after {
  content: "";
  display: table;
  clear: both;
}

.lg-container {
  display: none;
  outline: none;
}
.lg-container.lg-show {
  display: block;
}

.lg-on {
  scroll-behavior: unset;
}

.lg-overlay-open {
  overflow: hidden;
}

.lg-toolbar,
.lg-prev,
.lg-next,
.lg-pager-outer,
.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-show-in .lg-toolbar,
.lg-show-in .lg-prev,
.lg-show-in .lg-next,
.lg-show-in .lg-pager-outer {
  opacity: 1;
}
.lg-show-in.lg-hide-sub-html .lg-sub-html {
  opacity: 1;
}
.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}
.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}
.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}
.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0.001;
  outline: none;
  will-change: auto;
  overflow: hidden;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.lg-outer.lg-zoom-from-image {
  opacity: 1;
}
.lg-outer.lg-visible {
  opacity: 1;
}
.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}
.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.lg-outer .lg-inner {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: opacity 0s;
  -o-transition: opacity 0s;
  transition: opacity 0s;
  white-space: nowrap;
}
.lg-outer .lg-item {
  display: none !important;
}
.lg-outer .lg-item:not(.lg-start-end-progress) {
  background: url("../images/loading.gif") no-repeat scroll center center transparent;
}
.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}
.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}
.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.lg-outer .lg-img-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  font-size: 0;
}
.lg-outer .lg-item.lg-complete {
  background-image: none;
}
.lg-outer .lg-item.lg-current {
  z-index: 1060;
}
.lg-outer .lg-object {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: relative;
}
.lg-outer .lg-empty-html.lg-sub-html,
.lg-outer .lg-empty-html .lg-sub-html {
  display: none;
}
.lg-outer.lg-hide-download .lg-download {
  opacity: 0.75;
  pointer-events: none;
}
.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%;
}
.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
  opacity: 1;
}
.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.15s;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  will-change: auto;
  -webkit-transition: opacity 333ms ease-in 0s;
  -o-transition: opacity 333ms ease-in 0s;
  transition: opacity 333ms ease-in 0s;
}
.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}
.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-fade .lg-item {
  opacity: 0;
}
.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}
.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}
.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-container {
  display: none;
}
.lg-container.lg-show {
  display: block;
}
.lg-container.lg-dragging-vertical .lg-backdrop {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-inline .lg-backdrop,
.lg-inline .lg-outer {
  position: absolute;
}
.lg-inline .lg-backdrop {
  z-index: 1;
}
.lg-inline .lg-outer {
  z-index: 2;
}
.lg-inline .lg-maximize:after {
  content: "\e909";
}

.lg-components {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  will-change: transform;
  -webkit-transition: -webkit-transform 0.35s ease-out 0s;
  -moz-transition: -moz-transform 0.35s ease-out 0s;
  -o-transition: -o-transform 0.35s ease-out 0s;
  transition: transform 0.35s ease-out 0s;
  z-index: 1080;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.lg-outer .lg-video-cont {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.lg-outer .lg-video-cont .lg-object {
  width: 100% !important;
  height: 100% !important;
}
.lg-outer .lg-has-iframe .lg-video-cont {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}
.lg-outer .lg-video-object {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 3;
}
.lg-outer .lg-video-poster {
  z-index: 1;
}
.lg-outer .lg-has-video .lg-video-object {
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}
.lg-outer .lg-has-video.lg-video-loaded .lg-video-poster,
.lg-outer .lg-has-video.lg-video-loaded .lg-video-play-button {
  opacity: 0 !important;
}
.lg-outer .lg-has-video.lg-video-loaded .lg-video-object {
  opacity: 1;
}

@keyframes lg-play-stroke {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes lg-play-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.lg-video-play-button {
  width: 18%;
  max-width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  cursor: pointer;
  transform: translate(-50%, -50%) scale(1);
  will-change: opacity, transform;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
  -o-transition: -o-transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
  transition: transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
}
.lg-video-play-button:hover .lg-video-play-icon-bg,
.lg-video-play-button:hover .lg-video-play-icon {
  opacity: 1;
}

.lg-video-play-icon-bg {
  fill: none;
  stroke-width: 3%;
  stroke: #fcfcfc;
  opacity: 0.6;
  will-change: opacity;
  -webkit-transition: opacity 0.12s ease-in;
  -o-transition: opacity 0.12s ease-in;
  transition: opacity 0.12s ease-in;
}

.lg-video-play-icon-circle {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  fill: none;
  stroke-width: 3%;
  stroke: rgba(30, 30, 30, 0.9);
  stroke-opacity: 1;
  stroke-linecap: round;
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
}

.lg-video-play-icon {
  position: absolute;
  width: 25%;
  max-width: 120px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0.6;
  will-change: opacity;
  -webkit-transition: opacity 0.12s ease-in;
  -o-transition: opacity 0.12s ease-in;
  transition: opacity 0.12s ease-in;
}
.lg-video-play-icon .lg-video-play-icon-inner {
  fill: #fcfcfc;
}

.lg-video-loading .lg-video-play-icon-circle {
  animation: lg-play-rotate 2s linear 0.25s infinite, lg-play-stroke 1.5s ease-in-out 0.25s infinite;
}

.lg-video-loaded .lg-video-play-button {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.7);
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap,
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0ms !important;
  transition-duration: 0ms !important;
}
.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
  -moz-transition: -moz-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
  -o-transition: -o-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
  transition: transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
}
.lg-outer.lg-use-transition-for-zoom.lg-zoom-drag-transition .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image,
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  will-change: opacity, transform;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  transition: transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-icon.lg-zoom-in:after {
  content: "\e311";
}
.lg-icon.lg-actual-size {
  font-size: 20px;
}
.lg-icon.lg-actual-size:after {
  content: "\e033";
}
.lg-icon.lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}
.lg-icon.lg-zoom-out:after {
  content: "\e312";
}
.lg-zoomed .lg-icon.lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer[data-lg-slide-type=video] .lg-zoom-in,
.lg-outer[data-lg-slide-type=video] .lg-actual-size,
.lg-outer[data-lg-slide-type=video] .lg-zoom-out, .lg-outer[data-lg-slide-type=iframe] .lg-zoom-in,
.lg-outer[data-lg-slide-type=iframe] .lg-actual-size,
.lg-outer[data-lg-slide-type=iframe] .lg-zoom-out, .lg-outer.lg-first-slide-loading .lg-zoom-in,
.lg-outer.lg-first-slide-loading .lg-actual-size,
.lg-outer.lg-first-slide-loading .lg-zoom-out {
  opacity: 0.75;
  pointer-events: none;
}

/*default-btn*/
.default-btn {
  transition: var(--transition);
  display: inline-block;
  padding: 13px 25px 12px 55px;
  position: relative;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-width: 2px;
  border-style: solid;
  border-color: var(--mainColor);
  border-radius: 1px;
  font-size: 14.5px;
  font-weight: 700;
}
.default-btn .label,
.default-btn .icon-arrow {
  backface-visibility: hidden;
  transform: translateZ(0);
  perspective: 1000;
}
.default-btn .label {
  display: inline-block;
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
}
.default-btn .icon-arrow {
  color: var(--whiteColor);
  top: 11px;
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  font-size: 25px;
}
.default-btn .icon-arrow.before {
  left: 20px;
  position: absolute;
  transform-origin: left center;
}
.default-btn .icon-arrow.after {
  opacity: 0;
  position: absolute;
  right: 20px;
  transform: translateX(75%) scaleX(0.1);
  transform-origin: right center;
}
.default-btn:hover, .default-btn:focus {
  background-color: var(--optionalColor);
  color: var(--whiteColor);
  border-color: var(--optionalColor);
}
.default-btn:hover .label, .default-btn:focus .label {
  transform: translateX(-32px);
}
.default-btn:hover .icon-arrow.before, .default-btn:focus .icon-arrow.before {
  opacity: 0;
  transform: translateX(-75%) scaleX(0.1);
}
.default-btn:hover .icon-arrow.after, .default-btn:focus .icon-arrow.after {
  opacity: 1;
  transform: translateX(0) scaleX(1);
}

/*section-title*/
.section-title {
  text-align: center;
  position: relative;
  margin-top: -5px;
  margin-bottom: 60px;
}
.section-title .sub-title {
  text-transform: uppercase;
  display: block;
  color: var(--mainColor);
  font-weight: 500;
  margin-bottom: 10px;
}
.section-title h2 {
  margin-bottom: 12px;
  font-size: 37px;
  font-weight: 700;
}
.section-title p {
  max-width: 620px;
  line-height: 1.8;
  margin-left: auto;
  margin-right: auto;
}
.section-title .default-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.section-title.text-start h2 {
  max-width: 620px;
  margin-bottom: 0;
}
.section-title.text-start p {
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 15px;
  margin-right: 0;
}

/*form-control*/
.form-control {
  height: 48px;
  padding: 0 0 0 12px;
  line-height: initial;
  color: var(--blackColor);
  background-color: var(--whiteColor);
  border: 1px solid #e6e9fc;
  border-radius: 3px;
  transition: var(--transition);
  font-size: var(--fontSize);
  font-weight: 400;
}
.form-control::placeholder {
  color: #c4c5d0;
}
.form-control:focus {
  box-shadow: unset;
  border-color: #d7d9eb;
}

textarea.form-control {
  height: auto !important;
  padding-top: 12px;
}

/*owl-carousel-css*/
.mission-content .owl-theme .owl-nav {
  margin-top: 0;
}
.mission-content .owl-theme .owl-nav [class*=owl-] {
  color: var(--blackColor);
  font-size: 25px;
  margin: 0;
  padding: 0;
  background: var(--whiteColor);
  width: 50px;
  height: 50px;
  line-height: 56px;
  position: absolute;
  left: -84px;
  transition: var(--transition);
  border: none;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 50%;
}
.mission-content .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -84px;
}
.mission-content .owl-theme .owl-nav [class*=owl-]:hover, .mission-content .owl-theme .owl-nav [class*=owl-]:focus {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.courses-categories-slides .owl-theme {
  margin-top: -30px;
}
.courses-categories-slides .owl-theme .single-categories-courses-box {
  margin-top: 30px;
}
.courses-categories-slides .owl-theme .single-categories-courses-item {
  margin-top: 30px;
}
.courses-categories-slides .owl-theme .owl-nav {
  margin-top: 0;
}
.courses-categories-slides .owl-theme .owl-nav [class*=owl-] {
  color: #87a1c1;
  font-size: 25px;
  margin: 0;
  padding: 0;
  background: transparent;
  width: 50px;
  height: 50px;
  line-height: 51px;
  position: absolute;
  left: -50px;
  transition: var(--transition);
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  border-style: solid;
  border-color: #e3e4ef;
  border-width: 2px;
}
.courses-categories-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -50px;
}
.courses-categories-slides .owl-theme .owl-nav [class*=owl-]:hover, .courses-categories-slides .owl-theme .owl-nav [class*=owl-]:focus {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
  box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
}
.courses-categories-slides .owl-theme .owl-nav [class*=owl-].disabled {
  color: #e3e4ef;
}
.courses-categories-slides .owl-theme .owl-nav [class*=owl-].disabled:hover, .courses-categories-slides .owl-theme .owl-nav [class*=owl-].disabled:focus {
  background-color: transparent;
  color: #e3e4ef;
  border-color: #e3e4ef;
  box-shadow: unset;
}
.courses-categories-slides .owl-theme:hover .owl-nav [class*=owl-], .courses-categories-slides .owl-theme:focus .owl-nav [class*=owl-] {
  left: -70px;
  opacity: 1;
  visibility: visible;
}
.courses-categories-slides .owl-theme:hover .owl-nav [class*=owl-].owl-next, .courses-categories-slides .owl-theme:focus .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -70px;
}

.courses-slides .owl-theme {
  margin-top: -25px;
}
.courses-slides .owl-theme .single-courses-box {
  margin-top: 25px;
}
.courses-slides .owl-theme .owl-nav {
  margin-top: 0;
}
.courses-slides .owl-theme .owl-nav [class*=owl-] {
  color: #87a1c1;
  font-size: 25px;
  margin: 0;
  padding: 0;
  background: transparent;
  width: 50px;
  height: 50px;
  line-height: 51px;
  position: absolute;
  left: -50px;
  transition: var(--transition);
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  border-style: solid;
  border-color: #e3e4ef;
  border-width: 2px;
}
.courses-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -50px;
}
.courses-slides .owl-theme .owl-nav [class*=owl-]:hover, .courses-slides .owl-theme .owl-nav [class*=owl-]:focus {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
  box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
}
.courses-slides .owl-theme .owl-nav [class*=owl-].disabled {
  color: #e3e4ef;
}
.courses-slides .owl-theme .owl-nav [class*=owl-].disabled:hover, .courses-slides .owl-theme .owl-nav [class*=owl-].disabled:focus {
  background-color: transparent;
  color: #e3e4ef;
  border-color: #e3e4ef;
  box-shadow: unset;
}
.courses-slides .owl-theme:hover .owl-nav [class*=owl-], .courses-slides .owl-theme:focus .owl-nav [class*=owl-] {
  left: -70px;
  opacity: 1;
  visibility: visible;
}
.courses-slides .owl-theme:hover .owl-nav [class*=owl-].owl-next, .courses-slides .owl-theme:focus .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -70px;
}
.courses-slides .owl-theme .owl-dots {
  margin-top: 15px;
}
.courses-slides .owl-theme .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  margin: 0 5px;
  background: #dddddd;
  transition: var(--transition);
  border-radius: 50%;
  transform: scale(0.9);
}
.courses-slides .owl-theme .owl-dots .owl-dot:hover span, .courses-slides .owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}
.courses-slides .owl-theme .owl-dots .owl-dot.active span {
  transform: scale(1.2);
}

.instructor-slides .owl-theme .owl-nav {
  margin-top: 0;
}
.instructor-slides .owl-theme .owl-nav [class*=owl-] {
  color: #87a1c1;
  font-size: 25px;
  margin: 0;
  padding: 0;
  background: transparent;
  width: 50px;
  height: 50px;
  line-height: 51px;
  position: absolute;
  left: -50px;
  transition: var(--transition);
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  border-style: solid;
  border-color: #e3e4ef;
  border-width: 2px;
}
.instructor-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -50px;
}
.instructor-slides .owl-theme .owl-nav [class*=owl-]:hover, .instructor-slides .owl-theme .owl-nav [class*=owl-]:focus {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
  box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
}
.instructor-slides .owl-theme .owl-nav [class*=owl-].disabled {
  color: #e3e4ef;
}
.instructor-slides .owl-theme .owl-nav [class*=owl-].disabled:hover, .instructor-slides .owl-theme .owl-nav [class*=owl-].disabled:focus {
  background-color: transparent;
  color: #e3e4ef;
  border-color: #e3e4ef;
  box-shadow: unset;
}
.instructor-slides .owl-theme:hover .owl-nav [class*=owl-], .instructor-slides .owl-theme:focus .owl-nav [class*=owl-] {
  left: -70px;
  opacity: 1;
  visibility: visible;
}
.instructor-slides .owl-theme:hover .owl-nav [class*=owl-].owl-next, .instructor-slides .owl-theme:focus .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -70px;
}

.partner-slides .owl-theme .owl-stage {
  display: flex;
  align-items: center;
}

.feedback-slides .owl-theme {
  margin-bottom: -40px;
}
.feedback-slides .owl-theme .single-feedback-item {
  margin-bottom: 40px;
}
.feedback-slides .owl-theme .owl-nav {
  margin-top: 0;
}
.feedback-slides .owl-theme .owl-nav [class*=owl-] {
  color: #87a1c1;
  font-size: 25px;
  margin: 0;
  padding: 0;
  background: transparent;
  width: 50px;
  height: 50px;
  line-height: 51px;
  position: absolute;
  left: 50px;
  transition: var(--transition);
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border-style: solid;
  border-color: #e3e4ef;
  border-width: 2px;
}
.feedback-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 50px;
}
.feedback-slides .owl-theme .owl-nav [class*=owl-]:hover, .feedback-slides .owl-theme .owl-nav [class*=owl-]:focus {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
  box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
}
.feedback-slides .owl-theme .owl-nav [class*=owl-].disabled {
  color: #e3e4ef;
}
.feedback-slides .owl-theme .owl-nav [class*=owl-].disabled:hover, .feedback-slides .owl-theme .owl-nav [class*=owl-].disabled:focus {
  background-color: transparent;
  color: #e3e4ef;
  border-color: #e3e4ef;
  box-shadow: unset;
}
.feedback-slides .owl-theme:hover .owl-nav [class*=owl-], .feedback-slides .owl-theme:focus .owl-nav [class*=owl-] {
  left: 70px;
}
.feedback-slides .owl-theme:hover .owl-nav [class*=owl-].owl-next, .feedback-slides .owl-theme:focus .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 70px;
}

.blog-slides .owl-theme .owl-nav {
  margin-top: 0;
}
.blog-slides .owl-theme .owl-nav [class*=owl-] {
  color: #87a1c1;
  font-size: 25px;
  margin: 0;
  padding: 0;
  background: transparent;
  width: 50px;
  height: 50px;
  line-height: 51px;
  position: absolute;
  left: -50px;
  transition: var(--transition);
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  border-style: solid;
  border-color: #e3e4ef;
  border-width: 2px;
}
.blog-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -50px;
}
.blog-slides .owl-theme .owl-nav [class*=owl-]:hover, .blog-slides .owl-theme .owl-nav [class*=owl-]:focus {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
  box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
}
.blog-slides .owl-theme .owl-nav [class*=owl-].disabled {
  color: #e3e4ef;
}
.blog-slides .owl-theme .owl-nav [class*=owl-].disabled:hover, .blog-slides .owl-theme .owl-nav [class*=owl-].disabled:focus {
  background-color: transparent;
  color: #e3e4ef;
  border-color: #e3e4ef;
  box-shadow: unset;
}
.blog-slides .owl-theme:hover .owl-nav [class*=owl-], .blog-slides .owl-theme:focus .owl-nav [class*=owl-] {
  left: -70px;
  opacity: 1;
  visibility: visible;
}
.blog-slides .owl-theme:hover .owl-nav [class*=owl-].owl-next, .blog-slides .owl-theme:focus .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -70px;
}

.home-slides .owl-theme .owl-nav {
  margin-top: 0;
  opacity: 0;
  visibility: visible;
  transition: var(--transition);
}
.home-slides .owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--blackColor);
  font-size: 35px;
  margin: 0;
  padding: 0;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0;
  width: 55px;
  height: 55px;
  transition: var(--transition);
}
.home-slides .owl-theme .owl-nav [class*=owl-] i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.home-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 20px;
}
.home-slides .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.home-slides .owl-theme .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
}
.home-slides .owl-theme .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  margin: 0 5px;
  background: var(--whiteColor);
  transition: var(--transition);
  border-radius: 50%;
  transform: scale(0.9);
}
.home-slides .owl-theme .owl-dots .owl-dot:hover span, .home-slides .owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}
.home-slides .owl-theme .owl-dots .owl-dot.active span {
  transform: scale(1.2);
}
.home-slides .owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.testimonials-slides .owl-theme .owl-dots {
  margin-top: 15px;
}
.testimonials-slides .owl-theme .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  margin: 0 5px;
  background: #dddddd;
  transition: var(--transition);
  border-radius: 50%;
  transform: scale(0.9);
}
.testimonials-slides .owl-theme .owl-dots .owl-dot:hover span, .testimonials-slides .owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}
.testimonials-slides .owl-theme .owl-dots .owl-dot.active span {
  transform: scale(1.2);
}

.products-details-image .slick-slider {
  position: relative;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.products-details-image .slick-dots {
  padding: 0;
  text-align: center;
  margin: 10px 0 0;
  list-style-type: none;
  display: flex;
  width: 100%;
}
.products-details-image .slick-dots li {
  display: block;
  -ms-flex: 1;
  flex: 1;
  margin: 0 5px;
}
.products-details-image .slick-dots li button {
  padding: 0;
  outline: 0;
  border: none;
  margin: 0;
  cursor: pointer;
}
.products-details-image .slick-dots li:last-child {
  margin-right: 0;
}
.products-details-image .slick-dots li:first-child {
  margin-left: 0;
}
.products-details-image .slick-thumbs {
  display: none;
}

.health-coaching-banner-slides .owl-theme .owl-nav {
  margin-top: 0;
}
.health-coaching-banner-slides .owl-theme .owl-nav [class*=owl-] {
  top: 50%;
  margin: 0;
  z-index: 1;
  left: 40px;
  line-height: 1;
  font-size: 35px;
  position: absolute;
  color: var(--mainColor2);
  transform: translateY(-50%);
  transition: var(--transition);
  padding: 0 0 0 6px !important;
  background-color: transparent !important;
}
.health-coaching-banner-slides .owl-theme .owl-nav [class*=owl-]::before {
  top: 0;
  left: 0;
  z-index: -1;
  content: "";
  width: 28px;
  height: 35px;
  border-radius: 2px;
  position: absolute;
  transition: var(--transition);
  border: 1px solid var(--mainColor2);
}
.health-coaching-banner-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 40px;
  padding: 0 6px 0 0 !important;
}
.health-coaching-banner-slides .owl-theme .owl-nav [class*=owl-].owl-next::before {
  left: auto;
  right: 0;
}
.health-coaching-banner-slides .owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--whiteColor);
}
.health-coaching-banner-slides .owl-theme .owl-nav [class*=owl-]:hover::before {
  border-color: var(--whiteColor);
}

/*keyframes-css*/
@keyframes loader {
  0% {
    left: -100px;
  }
  100% {
    left: 110%;
  }
}
@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
@keyframes shadow {
  50% {
    transform: scale(1.2, 1);
  }
}
@keyframes rotateMe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes movebounce2 {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes movebounce3 {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
/*extra-css*/
.courses-categories-area {
  position: relative;
  z-index: 1;
}
.courses-categories-area.bg-image {
  background-image: url(assets/img/pattern.jpg);
}

.owl-item:nth-child(2) .single-categories-courses-box, .owl-item:nth-child(8) .single-categories-courses-box {
  background-color: #fea116;
}
.owl-item:nth-child(2) .single-categories-courses-box:hover .icon, .owl-item:nth-child(2) .single-categories-courses-box:focus .icon, .owl-item:nth-child(8) .single-categories-courses-box:hover .icon, .owl-item:nth-child(8) .single-categories-courses-box:focus .icon {
  border-color: #fea116 !important;
  color: #fea116 !important;
}
.owl-item:nth-child(3) .single-categories-courses-box, .owl-item:nth-child(9) .single-categories-courses-box {
  background-color: #1cb5a3;
}
.owl-item:nth-child(3) .single-categories-courses-box:hover .icon, .owl-item:nth-child(3) .single-categories-courses-box:focus .icon, .owl-item:nth-child(9) .single-categories-courses-box:hover .icon, .owl-item:nth-child(9) .single-categories-courses-box:focus .icon {
  border-color: #1cb5a3 !important;
  color: #1cb5a3 !important;
}
.owl-item:nth-child(4) .single-categories-courses-box, .owl-item:nth-child(10) .single-categories-courses-box {
  background-color: #3d64ff;
}
.owl-item:nth-child(4) .single-categories-courses-box:hover .icon, .owl-item:nth-child(4) .single-categories-courses-box:focus .icon, .owl-item:nth-child(10) .single-categories-courses-box:hover .icon, .owl-item:nth-child(10) .single-categories-courses-box:focus .icon {
  border-color: #3d64ff !important;
  color: #3d64ff !important;
}
.owl-item:nth-child(5) .single-categories-courses-box, .owl-item:nth-child(11) .single-categories-courses-box {
  background-color: #e74d57;
}
.owl-item:nth-child(5) .single-categories-courses-box:hover .icon, .owl-item:nth-child(5) .single-categories-courses-box:focus .icon, .owl-item:nth-child(11) .single-categories-courses-box:hover .icon, .owl-item:nth-child(11) .single-categories-courses-box:focus .icon {
  border-color: #e74d57 !important;
  color: #e74d57 !important;
}
.owl-item:nth-child(6) .single-categories-courses-box, .owl-item:nth-child(12) .single-categories-courses-box {
  background-color: #9764df;
}
.owl-item:nth-child(6) .single-categories-courses-box:hover .icon, .owl-item:nth-child(6) .single-categories-courses-box:focus .icon, .owl-item:nth-child(12) .single-categories-courses-box:hover .icon, .owl-item:nth-child(12) .single-categories-courses-box:focus .icon {
  border-color: #9764df !important;
  color: #9764df !important;
}
.owl-item:nth-child(2) .single-instructor-box .image, .owl-item:nth-child(6) .single-instructor-box .image {
  border-color: #ffe5be !important;
}
.owl-item:nth-child(2) .single-instructor-box .image .social li a:hover, .owl-item:nth-child(6) .single-instructor-box .image .social li a:hover {
  color: var(--whiteColor) !important;
  background-color: #fea116 !important;
}
.owl-item:nth-child(2) .single-instructor-box .content span, .owl-item:nth-child(6) .single-instructor-box .content span {
  color: #fea116 !important;
}
.owl-item:nth-child(2) .single-instructor-box:hover .image, .owl-item:nth-child(6) .single-instructor-box:hover .image {
  border-color: #fea116 !important;
}
.owl-item:nth-child(3) .single-instructor-box .image, .owl-item:nth-child(5) .single-instructor-box .image {
  border-color: #b2f3df !important;
}
.owl-item:nth-child(3) .single-instructor-box .image .social li a:hover, .owl-item:nth-child(5) .single-instructor-box .image .social li a:hover {
  color: var(--whiteColor) !important;
  background-color: var(--optionalColor) !important;
}
.owl-item:nth-child(3) .single-instructor-box .content span, .owl-item:nth-child(5) .single-instructor-box .content span {
  color: var(--optionalColor) !important;
}
.owl-item:nth-child(3) .single-instructor-box:hover .image, .owl-item:nth-child(5) .single-instructor-box:hover .image {
  border-color: var(--optionalColor) !important;
}
.owl-item:nth-child(2) .single-blog-post .post-image .tag a, .owl-item:nth-child(7) .single-blog-post .post-image .tag a {
  color: var(--optionalColor) !important;
}
.owl-item:nth-child(2) .single-blog-post:hover .post-image .tag a, .owl-item:nth-child(2) .single-blog-post:focus .post-image .tag a, .owl-item:nth-child(7) .single-blog-post:hover .post-image .tag a, .owl-item:nth-child(7) .single-blog-post:focus .post-image .tag a {
  background-color: var(--optionalColor) !important;
  color: var(--whiteColor) !important;
}
.owl-item:nth-child(3) .single-blog-post .post-image .tag a, .owl-item:nth-child(8) .single-blog-post .post-image .tag a {
  color: #fea116 !important;
}
.owl-item:nth-child(3) .single-blog-post:hover .post-image .tag a, .owl-item:nth-child(3) .single-blog-post:focus .post-image .tag a, .owl-item:nth-child(8) .single-blog-post:hover .post-image .tag a, .owl-item:nth-child(8) .single-blog-post:focus .post-image .tag a {
  background-color: #fea116 !important;
  color: var(--whiteColor) !important;
}
.owl-item:nth-child(4) .single-blog-post .post-image .tag a, .owl-item:nth-child(9) .single-blog-post .post-image .tag a {
  color: #1cb5a3 !important;
}
.owl-item:nth-child(4) .single-blog-post:hover .post-image .tag a, .owl-item:nth-child(4) .single-blog-post:focus .post-image .tag a, .owl-item:nth-child(9) .single-blog-post:hover .post-image .tag a, .owl-item:nth-child(9) .single-blog-post:focus .post-image .tag a {
  background-color: #1cb5a3 !important;
  color: var(--whiteColor) !important;
}
.owl-item:nth-child(5) .single-blog-post .post-image .tag a, .owl-item:nth-child(10) .single-blog-post .post-image .tag a {
  color: #3d64ff !important;
}
.owl-item:nth-child(5) .single-blog-post:hover .post-image .tag a, .owl-item:nth-child(5) .single-blog-post:focus .post-image .tag a, .owl-item:nth-child(10) .single-blog-post:hover .post-image .tag a, .owl-item:nth-child(10) .single-blog-post:focus .post-image .tag a {
  background-color: #3d64ff !important;
  color: var(--whiteColor) !important;
}
.owl-item .single-testimonials-item {
  box-shadow: unset;
  margin-top: 30px;
  transition: var(--transition);
  opacity: 0.7;
}
.owl-item.active.center .single-testimonials-item {
  box-shadow: 6px 5px 25px rgba(0, 0, 0, 0.06);
  margin-top: 0;
  background-color: var(--mainColor);
  transition: var(--transition);
  opacity: 1;
}
.owl-item.active.center .single-testimonials-item p {
  color: var(--whiteColor);
}
.owl-item.active .single-banner-item h1 {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInRight;
  animation-delay: 0.7s;
}
.owl-item.active .single-banner-item p {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInRight;
  animation-delay: 0.9s;
}
.owl-item.active .single-banner-item .default-btn {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInRight;
  animation-delay: 1.1s;
}

.funfacts-area {
  overflow: hidden;
}

.funfacts-inner {
  overflow: hidden;
  background-color: var(--mainColor);
  border-radius: 15px;
  padding: 50px;
  position: relative;
  z-index: 1;
  text-align: center;
}
.funfacts-inner .col-lg-3 {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.funfacts-inner .col-lg-3:last-child {
  border-right: 0;
}

.partner-area.bg-color {
  background-color: var(--mainColor);
}
.partner-area.bg-color .section-title h2 {
  color: var(--whiteColor);
}

.instructor-area {
  position: relative;
  z-index: 2;
}

.feedback-area {
  position: relative;
  z-index: 1;
  background-color: #f7f5f4;
}

.feedback-form-area {
  position: relative;
  z-index: 1;
  margin-top: -80px;
}

.testimonials-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.funfacts-style-two {
  overflow: hidden;
  background-color: var(--mainColor);
  position: relative;
  z-index: 1;
}

.slideshow-box {
  background-color: var(--blackColor);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.slideshow-box owl-carousel-o {
  width: 100%;
  height: 100%;
}
.slideshow-box .slideshow-slides {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.slideshow-box .slideshow-slides li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.slideshow-box .slideshow-slides li span.bg1 {
  background-image: url(assets/img/slideshow/slideshow1.jpg);
}
.slideshow-box .slideshow-slides li span.bg2 {
  background-image: url(assets/img/slideshow/slideshow2.jpg);
}
.slideshow-box .slideshow-slides li span.bg3 {
  background-image: url(assets/img/slideshow/slideshow3.jpg);
}
.slideshow-box .slideshow-slides li span.bg4 {
  background-image: url(assets/img/slideshow/slideshow4.jpg);
}
.slideshow-box .slideshow-slides li span.bg5 {
  background-image: url(assets/img/slideshow/slideshow5.jpg);
}
.slideshow-box .slideshow-slides .owl-theme {
  height: 100%;
  width: 100%;
}
.slideshow-box .slideshow-slides owl-stage {
  height: 100%;
  width: 100%;
}
.slideshow-box .slideshow-slides .ng-tns-c18-0 {
  height: 100%;
  width: 100%;
}
.slideshow-box .slideshow-slides div {
  height: 100%;
  width: 100%;
}
.slideshow-box .slideshow-slides .owl-stage-outer {
  height: 100%;
  width: 100%;
}
.slideshow-box .slideshow-slides .owl-stage-outer .owl-stage {
  height: 100%;
  width: 100%;
}
.slideshow-box .slideshow-slides .owl-stage-outer .owl-stage .owl-item {
  height: 100% !important;
}

.woocommerce-topbar .topbar-ordering-and-search .topbar-ordering .form-select {
  border: none;
  color: var(--paragraphColor);
  font-size: var(--fontSize);
  padding: 0;
  cursor: pointer;
  box-shadow: unset;
}

.courses-topbar {
  margin-bottom: 40px;
}
.courses-topbar .topbar-result-count p {
  line-height: initial;
  font-family: var(--fontFamily1);
  font-size: 16px;
}
.courses-topbar .topbar-ordering-and-search {
  text-align: end;
}
.courses-topbar .topbar-ordering-and-search .topbar-ordering .form-select {
  border: none;
  box-shadow: unset;
  cursor: pointer;
  padding: 0;
  font-size: var(--fontSize);
  color: var(--paragraphColor);
}
.courses-topbar .topbar-ordering-and-search .topbar-search form {
  position: relative;
}
.courses-topbar .topbar-ordering-and-search .topbar-search form label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 20px;
  top: 14.2px;
  font-size: 18px;
  color: var(--mainColor);
}
.courses-topbar .topbar-ordering-and-search .topbar-search form .input-search {
  height: 50px;
  color: var(--blackColor);
  background-color: #eef5f9;
  display: block;
  width: 100%;
  border-radius: 30px;
  padding: 3px 15px 0 48px;
  border: none;
  transition: var(--transition);
  font-size: 14px;
  font-weight: 400;
}
.courses-topbar .topbar-ordering-and-search .topbar-search form .input-search::placeholder {
  color: var(--paragraphColor);
  transition: var(--transition);
}
.courses-topbar .topbar-ordering-and-search .topbar-search form .input-search:focus::placeholder {
  color: transparent;
}

.divider {
  width: 100%;
  position: absolute;
  height: 6vw;
  bottom: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  background: var(--whiteColor);
}

.mr-2 {
  margin-right: 0.5rem;
}

.nav-tabset {
  padding-left: 0;
  list-style-type: none;
  margin-top: -30px;
  margin-bottom: 40px;
}
.nav-tabset .nav-tab {
  display: inline-block;
  margin-right: 20px;
}
.nav-tabset .nav-tab span {
  transition: var(--transition);
  background-color: transparent;
  color: #727695;
  font-weight: 500;
  cursor: pointer;
  display: block;
  border: none;
  padding: 0;
}
.nav-tabset .nav-tab span:hover {
  color: var(--mainColor);
}
.nav-tabset .nav-tab.active span {
  color: var(--mainColor);
}

.courses-list-tab .nav-tabset {
  text-align: center;
  margin-bottom: 40px;
  list-style-type: none;
  background-color: var(--whiteColor);
  box-shadow: 0 2px 16px 0px rgba(146, 184, 255, 0.15);
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
}
.courses-list-tab .nav-tabset .nav-tab {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}
.courses-list-tab .nav-tabset .nav-tab:last-child {
  margin-right: 0;
}
.courses-list-tab .nav-tabset .nav-tab:first-child {
  margin-left: 0;
}
.courses-list-tab .nav-tabset .nav-tab span {
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
}
.courses-list-tab .nav-tabset .nav-tab span::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  transition: var(--transition);
  background: #eeeeee;
}
.courses-list-tab .nav-tabset .nav-tab span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  transition: var(--transition);
  background-color: var(--mainColor);
  transform: scaleX(0);
}
.courses-list-tab .nav-tabset .nav-tab span:hover {
  color: var(--mainColor);
}
.courses-list-tab .nav-tabset .nav-tab span:hover::after {
  transform: scaleX(1);
}
.courses-list-tab .nav-tabset .nav-tab.active span {
  color: var(--mainColor);
}
.courses-list-tab .nav-tabset .nav-tab.active span::after {
  transform: scaleX(1);
}

.faq-accordion-tab .nav-tabset {
  margin-bottom: 60px;
  text-align: center;
}
.faq-accordion-tab .nav-tabset li {
  margin-left: 10px;
  margin-right: 10px;
}
.faq-accordion-tab .nav-tabset li:last-child {
  margin-right: 0;
}
.faq-accordion-tab .nav-tabset li:first-child {
  margin-left: 0;
}
.faq-accordion-tab .nav-tabset li span {
  padding: 20px;
  border-radius: 5px;
  transition: var(--transition);
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13);
  font-size: 16px;
  font-weight: 600;
}
.faq-accordion-tab .nav-tabset li span:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.faq-accordion-tab .nav-tabset li.active span {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.products-details-tab {
  margin-top: 50px;
}
.products-details-tab .nav-tabset {
  margin-bottom: -1px;
}
.products-details-tab .nav-tabset .nav-tab {
  display: inline-block;
  line-height: initial;
  margin-right: 10px;
}
.products-details-tab .nav-tabset .nav-tab span {
  position: relative;
  display: inline-block;
  color: var(--blackColor);
  border: 1px dashed #eeeeee;
  padding-top: 13px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 13px;
  font-weight: 700;
  font-size: var(--fontSize);
}
.products-details-tab .nav-tabset .nav-tab span:hover {
  background-color: var(--mainColor);
  border-color: var(--mainColor);
  color: var(--whiteColor);
}
.products-details-tab .nav-tabset .nav-tab.active span {
  background-color: var(--mainColor);
  border-color: var(--mainColor);
  color: var(--whiteColor);
}
.products-details-tab .nav-tabset .nav-tab:last-child {
  margin-right: 0;
}
.products-details-tab .tabs-container {
  border: 1px dashed #eeeeee;
  padding: 30px;
}
.products-details-tab .tabs-container .products-details-tab-content p {
  margin-bottom: 20px;
  line-height: 1.8;
}
.products-details-tab .tabs-container .products-details-tab-content p:last-child {
  margin-bottom: 0;
}
.products-details-tab .tabs-container .products-details-tab-content .additional-information {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: start;
}
.products-details-tab .tabs-container .products-details-tab-content .additional-information li {
  border: 1px solid #eeeeee;
  border-bottom: none;
  padding: 10px 15px 7px;
  color: var(--paragraphColor);
}
.products-details-tab .tabs-container .products-details-tab-content .additional-information li:last-child {
  border-bottom: 1px solid #eeeeee;
}
.products-details-tab .tabs-container .products-details-tab-content .additional-information li span {
  display: inline-block;
  width: 30%;
  color: var(--blackColor);
  font-weight: 500;
}
.products-details-tab .tabs-container .products-details-tab-content h3 {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-title {
  position: relative;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-title .rating {
  display: inline-block;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-title .rating .bx.bxs-star {
  color: #ffba0a;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-title .rating i {
  color: #ebebeb;
  font-size: 18px;
  margin-right: 2px;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-title p {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 5px;
  line-height: initial;
  position: relative;
  top: -2px;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-title .default-btn {
  position: absolute;
  right: 0;
  bottom: 0;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-comments {
  margin-top: 35px;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-comments .review-item {
  margin-top: 30px;
  position: relative;
  padding-right: 200px;
  border-top: 1px dashed #eeeeee;
  padding-top: 30px;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-comments .review-item .rating .bx.bxs-star {
  color: #ffba0a;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-comments .review-item .rating i {
  font-size: 18px;
  color: #ebebeb;
  margin-right: -1px;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-comments .review-item h3 {
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 10px;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-comments .review-item span {
  margin-bottom: 10px;
  font-size: 12px;
  display: block;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-comments .review-item span strong {
  font-weight: 500;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-comments .review-item p {
  margin-bottom: 0;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link {
  position: absolute;
  right: 0;
  color: var(--paragraphColor);
  top: 40px;
  text-decoration: underline;
  font-weight: 500;
  font-size: 15px;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link:hover {
  color: var(--mainColor);
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-form {
  margin-top: 30px;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-form h3 {
  margin-bottom: 20px;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-form form .form-group {
  margin-bottom: 15px;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-form form .form-group .form-control {
  display: block;
  width: 100%;
  background-color: #f4f4f4;
  border: none;
  padding: 0 0 0 15px;
  height: 50px;
  outline: 0;
  border-radius: 3px;
  font-family: var(--fontFamily1);
  font-weight: 400;
  font-size: 14px;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-form form .form-group .form-control::placeholder {
  transition: var(--transition);
  color: var(--paragraphColor);
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-form form .form-group textarea.form-control {
  padding-top: 15px;
  height: auto;
}
.products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-form form .default-btn {
  margin-top: 10px;
}

.feedback-slides-two .owl-theme .owl-nav.disabled + .owl-dots {
  transform: translateY(-50%);
  position: absolute;
  margin-top: 0;
  right: 20px;
  top: 50%;
}
.feedback-slides-two .owl-theme .owl-dots .owl-dot {
  display: block;
}
.feedback-slides-two .owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 5px 0;
  border-radius: 50%;
  position: relative;
  border: 1px solid #a8a8a8;
  transition: var(--transition);
  background-color: transparent !important;
}
.feedback-slides-two .owl-theme .owl-dots .owl-dot span::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 50%;
  margin: 2px;
  transition: var(--transition);
}
.feedback-slides-two .owl-theme .owl-dots .owl-dot:hover span, .feedback-slides-two .owl-theme .owl-dots .owl-dot.active span {
  border-color: var(--mainColor);
}
.feedback-slides-two .owl-theme .owl-dots .owl-dot:hover span::before, .feedback-slides-two .owl-theme .owl-dots .owl-dot.active span::before {
  background-color: var(--mainColor);
}

.feedback-area-two .feedback-slides .owl-theme {
  margin-bottom: 0;
}
.feedback-area-two .feedback-slides .owl-theme .owl-nav [class*=owl-] {
  left: 0;
  opacity: 1;
  width: auto;
  border: none;
  height: auto;
  line-height: 1;
  font-size: 40px;
  visibility: visible;
  color: #9d9d9d !important;
  box-shadow: unset !important;
  background-color: transparent !important;
}
.feedback-area-two .feedback-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 30px;
}
.feedback-area-two .feedback-slides .owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor) !important;
}
.feedback-area-two .feedback-slides .owl-theme .single-feedback-box {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}
.feedback-area-two .section-title {
  margin-bottom: 20px;
}

.gym-feedback-inner .feedback-slides-two .owl-theme .owl-nav.disabled + .owl-dots {
  transform: translateY(0);
  position: relative;
  margin-top: 25px;
  right: 0;
  top: 0;
}
.gym-feedback-inner .feedback-slides-two .owl-theme .owl-dots .owl-dot {
  display: inline-block;
}
.gym-feedback-inner .feedback-slides-two .owl-theme .owl-dots .owl-dot span {
  margin: 0 4px;
}

.gym-home-slides .owl-theme {
  margin-bottom: -2px;
}
.gym-home-slides .owl-theme .owl-dots {
  left: 0;
  right: 0;
  bottom: 20px;
  position: absolute;
}
.gym-home-slides .owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 0 4px;
  position: relative;
  border-radius: 50%;
  border: 1px solid #a8a8a8;
  transition: var(--transition);
  background-color: transparent !important;
}
.gym-home-slides .owl-theme .owl-dots .owl-dot span::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 2px;
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: transparent;
  transition: var(--transition);
}
.gym-home-slides .owl-theme .owl-dots .owl-dot:hover span, .gym-home-slides .owl-theme .owl-dots .owl-dot.active span {
  border-color: var(--mainColor);
}
.gym-home-slides .owl-theme .owl-dots .owl-dot:hover span::before, .gym-home-slides .owl-theme .owl-dots .owl-dot.active span::before {
  background-color: var(--mainColor);
}
.gym-home-slides .owl-theme .owl-nav {
  opacity: 0;
  margin-top: 0;
  visibility: hidden;
  transition: var(--transition);
}
.gym-home-slides .owl-theme .owl-nav [class*=owl-] {
  top: 50%;
  margin: 0;
  left: 25px;
  width: 50px;
  height: 50px;
  font-size: 25px;
  line-height: 52px;
  position: absolute;
  border-radius: 4px;
  padding: 0 !important;
  color: var(--blackColor);
  transform: translateY(-50%);
  transition: var(--transition);
  background-color: rgba(255, 255, 255, 0.45);
}
.gym-home-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 25px;
}
.gym-home-slides .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.gym-home-slides .owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.motivation-partner-area.bg-color-with-gradient {
  background: linear-gradient(270deg, #ff305b 0%, #fd6e00 50%, #ff3142 100%);
}

.testimonials-wrap-area {
  background: #faf8f8;
  overflow: hidden;
}
.testimonials-wrap-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.testimonials-wrap-area.bg-E7F0FD {
  background-color: #e7f0fd;
  margin-bottom: -155px;
  padding-bottom: 245px;
}

/*Page Title CSS*/
.page-title-area {
  position: relative;
  z-index: 1;
  background-color: var(--mainColor);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 330px;
  padding-bottom: 120px;
}
.page-title-area.page-title-style-two {
  padding-top: 280px;
  padding-bottom: 120px;
}
.page-title-area.page-title-style-three {
  padding-top: 180px;
  padding-bottom: 140px;
}
.page-title-area.item-bg-courses {
  background-image: url(assets/img/img-courses.jpeg);
}
.page-title-area.item-bg1 {
  background-image: url(assets/img/page-title/page-title1.jpg);
}
.page-title-area.item-bg2 {
  background-image: url(assets/img/page-title/page-title2.jpg);
}
.page-title-area.item-bg3 {
  background-image: url(assets/img/page-title/page-title3.jpg);
}
.page-title-area.item-bg4 {
  background-image: url(assets/img/page-title/page-title4.jpg);
}
.page-title-area.item-bg5 {
  background-image: url(assets/img/contact-us.jpeg);
}
.page-title-area.item-bg6 {
  background-image: url(assets/img/img-call.jpeg);
}

.page-title-content ul {
  padding-left: 0;
  margin-bottom: 12px;
  list-style-type: none;
}
.page-title-content ul li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
  color: var(--whiteColor);
}
.page-title-content ul li a {
  display: inline-block;
  color: var(--whiteColor);
  opacity: 0.85;
}
.page-title-content ul li a:hover, .page-title-content ul li a:focus {
  color: var(--mainColor);
  opacity: 1;
}
.page-title-content ul li::before {
  content: "\ea50";
  color: var(--whiteColor);
  position: absolute;
  right: -25px;
  top: -4px;
  opacity: 0.85;
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-size: 20px;
}
.page-title-content ul li:last-child {
  margin-right: 0;
}
.page-title-content ul li:last-child::before {
  display: none;
}
.page-title-content h2 {
  color: var(--whiteColor);
  margin-bottom: 0;
  font-size: 42px;
  font-weight: 700;
}

/*Widget Sidebar CSS*/
.widget-area .widget {
  margin-bottom: 40px;
}
.widget-area .widget:last-child {
  margin-bottom: 0;
}
.widget-area .widget .widget-title {
  margin-bottom: 25px;
  position: relative;
  padding-left: 10px;
  border-left: 3px dashed #dfdfdf;
  font-size: 19px;
  font-weight: 600;
}
.widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  left: -3px;
  top: 0;
  width: 3px;
  height: 6px;
  background: var(--mainColor);
}
.widget-area .widget .widget-title::after {
  content: "";
  position: absolute;
  left: -3px;
  top: 16px;
  width: 3px;
  height: 6px;
  background: var(--optionalColor);
}
.widget-area .widget_search form {
  position: relative;
}
.widget-area .widget_search form .screen-reader-text {
  display: none;
}
.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.widget-area .widget_search form .search-field {
  height: 55px;
  color: var(--blackColor);
  background-color: #eef5f9;
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 3px 0 0 18px;
  border: none;
  transition: var(--transition);
  font-size: 14px;
  font-weight: 400;
}
.widget-area .widget_search form .search-field::placeholder {
  color: var(--paragraphColor);
  transition: var(--transition);
}
.widget-area .widget_search form .search-field:focus::placeholder {
  color: transparent;
}
.widget-area .widget_search form button {
  border: none;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  height: 45px;
  width: 45px;
  position: absolute;
  right: 5px;
  transition: var(--transition);
  border-radius: 5px;
  top: 5px;
  font-size: 20px;
}
.widget-area .widget_search form button:hover, .widget-area .widget_search form button:focus {
  background-color: var(--optionalColor);
  color: var(--whiteColor);
}
.widget-area .widget_ednuv_posts_thumb {
  position: relative;
  overflow: hidden;
}
.widget-area .widget_ednuv_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}
.widget-area .widget_ednuv_posts_thumb .item:last-child {
  margin-bottom: 0;
}
.widget-area .widget_ednuv_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 80px;
  margin-right: 15px;
  z-index: 1;
}
.widget-area .widget_ednuv_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_ednuv_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(assets/img/blog/blog7.jpg);
}
.widget-area .widget_ednuv_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(assets/img/blog/blog8.jpg);
}
.widget-area .widget_ednuv_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(assets/img/blog/blog9.jpg);
}
.widget-area .widget_ednuv_posts_thumb .item .thumb::before, .widget-area .widget_ednuv_posts_thumb .item .thumb::after {
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: "";
  background-color: var(--whiteColor);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}
.widget-area .widget_ednuv_posts_thumb .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}
.widget-area .widget_ednuv_posts_thumb .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}
.widget-area .widget_ednuv_posts_thumb .item .info {
  overflow: hidden;
  margin-top: 5px;
}
.widget-area .widget_ednuv_posts_thumb .item .info time {
  display: block;
  color: var(--paragraphColor);
  text-transform: uppercase;
  margin-top: 3px;
  margin-bottom: 5px;
  font-size: 12px;
}
.widget-area .widget_ednuv_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 600;
}
.widget-area .widget_ednuv_posts_thumb .item .info .title a {
  display: inline-block;
}
.widget-area .widget_ednuv_posts_thumb .item:hover .thumb::before, .widget-area .widget_ednuv_posts_thumb .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}
.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  overflow: hidden;
  color: var(--blackColor);
  padding-left: 18px;
  font-weight: 600;
  font-size: 14px;
}
.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_categories ul li::before {
  background: var(--optionalColor);
  height: 8px;
  width: 8px;
  content: "";
  border-radius: 50%;
  left: 0;
  top: 7px;
  position: absolute;
}
.widget-area .widget_categories ul li a {
  color: var(--blackColor);
  display: inline-block;
}
.widget-area .widget_categories ul li a:hover {
  color: var(--mainColor);
}
.widget-area .widget_categories ul li .post-count {
  color: var(--paragraphColor);
  font-size: 14px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 17px;
}
.widget-area .tagcloud a {
  display: inline-block;
  background-color: #f6f6f6;
  color: var(--paragraphColor);
  padding: 7px 18px;
  border: 1px dashed #eeeeee;
  border-radius: 3px;
  font-weight: 500;
  font-size: 13.5px !important;
  margin-top: 8px;
  margin-right: 8px;
}
.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}
.widget-area .widget_instagram ul {
  padding-left: 0;
  list-style-type: none;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: -10px;
  margin-left: -5px;
  margin-right: -5px;
}
.widget-area .widget_instagram ul li {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
}
.widget-area .widget_instagram ul li a {
  position: relative;
}
.widget-area .widget_instagram ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--mainColor);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}
.widget-area .widget_instagram ul li a:hover::before {
  opacity: 0.7;
  visibility: visible;
}
.widget-area .widget_contact {
  position: relative;
  z-index: 1;
  border-radius: 5px;
  height: 400px;
  text-align: center;
  background-image: url(assets/img/acceuil.jpeg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.widget-area .widget_contact::before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
  background-color: var(--mainColor);
  opacity: 0.6;
  z-index: -1;
}
.widget-area .widget_contact .text {
  position: absolute;
  left: 0;
  bottom: 25px;
  right: 0;
}
.widget-area .widget_contact .text .icon {
  width: 65px;
  height: 65px;
  border: 2px dashed var(--whiteColor);
  position: relative;
  color: var(--whiteColor);
  border-radius: 50%;
  font-size: 35px;
  transition: var(--transition);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.widget-area .widget_contact .text .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.widget-area .widget_contact .text span {
  display: block;
  color: var(--whiteColor);
  margin-bottom: 7px;
  opacity: 0.9;
  font-size: 16px;
  font-weight: 500;
}
.widget-area .widget_contact .text a {
  display: inline-block;
  color: var(--whiteColor);
  font-weight: 700;
  font-size: 25px;
}
.widget-area .widget_contact:hover .text .icon {
  background-color: var(--whiteColor);
  color: var(--mainColor);
  border-color: var(--whiteColor);
}
.widget-area .widget_contact-courses {
  position: relative;
  z-index: 1;
  border-radius: 5px;
  height: 400px;
  text-align: center;
  background-image: url(assets/img/img-call.jpeg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.widget-area .widget_contact-courses::before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
  background-color: var(--mainColor);
  opacity: 0.6;
  z-index: -1;
}
.widget-area .widget_contact-courses .text {
  position: absolute;
  left: 0;
  bottom: 25px;
  right: 0;
}
.widget-area .widget_contact-courses .text .icon {
  width: 65px;
  height: 65px;
  border: 2px dashed var(--whiteColor);
  position: relative;
  color: var(--whiteColor);
  border-radius: 50%;
  font-size: 35px;
  transition: var(--transition);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.widget-area .widget_contact-courses .text .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.widget-area .widget_contact-courses .text span {
  display: block;
  color: var(--whiteColor);
  margin-bottom: 7px;
  opacity: 0.9;
  font-size: 16px;
  font-weight: 500;
}
.widget-area .widget_contact-courses .text a {
  display: inline-block;
  color: var(--whiteColor);
  font-weight: 700;
  font-size: 25px;
}
.widget-area .widget_contact-courses:hover .text .icon {
  background-color: var(--whiteColor);
  color: var(--mainColor);
  border-color: var(--whiteColor);
}
.widget-area .widget_events_details ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_events_details ul li {
  border-bottom: 1px dashed #eeeeee;
  color: var(--paragraphColor);
  padding-bottom: 10px;
  padding-top: 10px;
}
.widget-area .widget_events_details ul li:first-child {
  border-top: 1px dashed #eeeeee;
}
.widget-area .widget_events_details ul li span {
  display: inline-block;
  color: var(--blackColor);
  font-weight: 600;
}
.widget-area .widget_events_details ul li a {
  display: inline-block;
  color: var(--paragraphColor);
  margin-left: 3px;
}
.widget-area .widget_events_details ul li a:hover {
  color: var(--mainColor);
}

/*Pagination CSS*/
.pagination-area {
  position: relative;
  z-index: 2;
  margin-top: 20px;
  margin-bottom: -12px;
}
.pagination-area .page-numbers {
  color: var(--blackColor);
  display: inline-block;
  font-weight: 500;
  font-size: 19px;
  margin-left: 6px;
  margin-right: 6px;
}
.pagination-area .page-numbers:hover, .pagination-area .page-numbers:focus, .pagination-area .page-numbers.current {
  color: var(--mainColor);
}
.pagination-area .page-numbers.prev, .pagination-area .page-numbers.next {
  font-size: 28px;
  position: relative;
  top: 5px;
  margin-left: -3px;
  margin-right: -3px;
}

/*post-navigation*/
.ednuv-post-navigation {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

.prev-link-wrapper {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
}
.prev-link-wrapper a {
  display: flex;
  align-items: center;
}
.prev-link-wrapper a:hover .image-prev::after {
  opacity: 1;
  visibility: visible;
}
.prev-link-wrapper a:hover .image-prev .post-nav-title {
  opacity: 1;
  visibility: visible;
}
.prev-link-wrapper a:hover .prev-link-info-wrapper {
  color: var(--mainColor);
}
.prev-link-wrapper .image-prev {
  display: inline-block;
  min-width: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  vertical-align: top;
  margin-right: 20px;
  position: relative;
  transition: var(--transition);
}
.prev-link-wrapper .image-prev img {
  border-radius: 5px;
}
.prev-link-wrapper .image-prev::after {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  background-color: var(--mainColor);
  visibility: hidden;
  transition: var(--transition);
}
.prev-link-wrapper .image-prev .post-nav-title {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  color: var(--whiteColor);
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
  font-size: 16px;
  font-weight: 500;
}
.prev-link-wrapper .prev-link-info-wrapper {
  color: var(--blackColor);
  transition: var(--transition);
}
.prev-link-wrapper .prev-title {
  display: inline-block;
  font-weight: 500;
  font-size: 17px;
}
.prev-link-wrapper .meta-wrapper {
  display: block;
  color: var(--paragraphColor);
  text-transform: capitalize;
  margin-top: 5px;
  font-weight: 400;
  font-size: 14px;
}

.next-link-wrapper {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: end;
  padding-left: 15px;
}
.next-link-wrapper a {
  display: flex;
  align-items: center;
}
.next-link-wrapper a:hover .image-next::after {
  opacity: 1;
  visibility: visible;
}
.next-link-wrapper a:hover .image-next .post-nav-title {
  opacity: 1;
  visibility: visible;
}
.next-link-wrapper a:hover .next-link-info-wrapper {
  color: var(--mainColor);
}
.next-link-wrapper .image-next {
  display: inline-block;
  min-width: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  vertical-align: top;
  margin-left: 20px;
  position: relative;
  transition: var(--transition);
}
.next-link-wrapper .image-next img {
  border-radius: 5px;
}
.next-link-wrapper .image-next::after {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  background-color: var(--mainColor);
  visibility: hidden;
  transition: var(--transition);
}
.next-link-wrapper .image-next .post-nav-title {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  color: var(--whiteColor);
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
  font-size: 16px;
  font-weight: 500;
}
.next-link-wrapper .next-link-info-wrapper {
  transition: var(--transition);
  color: var(--blackColor);
}
.next-link-wrapper .next-title {
  display: inline-block;
  font-weight: 500;
  font-size: 17px;
}
.next-link-wrapper .meta-wrapper {
  display: block;
  color: var(--paragraphColor);
  text-transform: capitalize;
  margin-top: 5px;
  font-weight: 400;
  font-size: 14px;
}

/*quote*/
blockquote,
.blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}
blockquote p,
.blockquote p {
  color: var(--blackColor);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px !important;
}
blockquote cite,
.blockquote cite {
  display: none;
}
blockquote::before,
.blockquote::before {
  color: #efefef;
  position: absolute;
  left: 50px;
  top: -50px;
  z-index: -1;
  content: "\ed67";
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-size: 135px;
}
blockquote::after,
.blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--mainColor);
  margin-top: 20px;
  margin-bottom: 20px;
}

/*shape-css*/
.business-shape1 {
  position: absolute;
  right: 5%;
  bottom: 0;
  z-index: -1;
}
.business-shape1 img {
  animation: movebounce3 8s linear infinite;
}

.business-shape2 {
  position: absolute;
  left: 0;
  bottom: -80px;
  z-index: -1;
}
.business-shape2 img {
  animation: movebounce2 8s linear infinite;
}

.business-shape3 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.business-shape4 {
  position: absolute;
  right: 50px;
  bottom: 50px;
  z-index: -1;
}
.business-shape4 img {
  animation: movebounce 8s linear infinite;
}

.business-shape5 {
  position: absolute;
  left: 50px;
  bottom: 50px;
  z-index: -1;
}
.business-shape5 img {
  animation: movebounce 8s linear infinite;
}

.business-shape6 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.business-shape6 img {
  animation: movebounce 8s linear infinite;
}

.business-shape7 {
  position: absolute;
  right: 50px;
  bottom: 50px;
  z-index: -1;
}
.business-shape7 img {
  animation: movebounce 8s linear infinite;
}

.business-shape8 {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.business-shape8 img {
  animation: movebounce2 8s linear infinite;
  max-width: 150px;
}

.business-shape9 {
  position: absolute;
  z-index: -1;
  left: 15%;
  top: 35%;
}
.business-shape9 img {
  animation: movebounce 8s linear infinite;
}

.business-shape10 {
  position: absolute;
  right: 15%;
  top: 25%;
  z-index: -1;
}
.business-shape10 img {
  animation-name: rotateMe;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.health-coaching-shape1 {
  position: absolute;
  right: 0;
  top: 8px;
  z-index: -1;
}
.health-coaching-shape1 img {
  animation: movebounce 8s linear infinite;
}

.health-coaching-shape2 {
  position: absolute;
  left: 0;
  bottom: 8px;
  z-index: -1;
}
.health-coaching-shape2 img {
  animation: movebounce2 8s linear infinite;
}

.health-coaching-shape3 {
  position: absolute;
  left: 8px;
  bottom: 0;
  z-index: -1;
}
.health-coaching-shape3 img {
  animation: movebounce3 8s linear infinite;
}

.health-coaching-shape4 {
  position: absolute;
  right: 3%;
  top: 65%;
  transform: translateY(-65%);
  z-index: -1;
}
.health-coaching-shape4 img {
  animation: movebounce 8s linear infinite;
  max-width: 300px;
}

.health-coaching-shape5 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  bottom: 4%;
}
.health-coaching-shape5 img {
  animation-name: rotateMe;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.health-coaching-shape6 {
  position: absolute;
  right: 5%;
  top: 8%;
  z-index: -1;
}
.health-coaching-shape6 img {
  animation: movebounce 8s linear infinite;
}

.health-coaching-shape7 {
  position: absolute;
  z-index: -1;
  left: 20px;
  bottom: 20px;
}
.health-coaching-shape7 img {
  animation: movebounce 8s linear infinite;
  max-width: 150px;
}

.health-coaching-shape8 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.health-coaching-shape8 img {
  max-width: 325px;
}

.health-coaching-shape9 {
  position: absolute;
  right: 7%;
  top: 30%;
  z-index: -1;
}
.health-coaching-shape9 img {
  animation: movebounce 8s linear infinite;
  max-width: 200px;
}

.lang-shape1 {
  position: absolute;
  left: 0;
  top: 16%;
  z-index: -1;
}
.lang-shape1 img {
  max-width: 100px;
  animation: movebounce2 8s linear infinite;
}

.lang-shape2 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.lang-shape2 img {
  animation: movebounce2 8s linear infinite;
  max-width: 170px;
}

.lang-shape3 {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.lang-shape3 img {
  animation: movebounce2 8s linear infinite;
  max-width: 250px;
}

.lang-shape4 {
  position: absolute;
  right: 5%;
  top: 30%;
  z-index: -1;
}
.lang-shape4 img {
  animation: movebounce3 8s linear infinite;
}

.lang-shape5 {
  position: absolute;
  left: 1%;
  bottom: 0;
  z-index: -1;
}
.lang-shape5 img {
  animation: movebounce3 8s linear infinite;
  max-width: 350px;
}

.gym-shape1 {
  position: absolute;
  top: 18%;
  left: 13%;
  z-index: -1;
}
.gym-shape1 img {
  animation: movebounce2 6s linear infinite;
}

.gym-shape2 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.gym-shape2 img {
  animation: movebounce3 6s linear infinite;
}

.motivation-categories-shape {
  left: 0;
  right: 0;
  z-index: -1;
  bottom: -120px;
  position: absolute;
  text-align: center;
}

.testimonials-wrap-slides .owl-theme .owl-dots {
  margin-top: 0;
}
.testimonials-wrap-slides .owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background: linear-gradient(30deg, #fd6d6d 13.39%, #ed3389 50%, #d837bf 86.61%);
  transition: var(--transition);
  border-radius: 30px;
}
.testimonials-wrap-slides .owl-theme .owl-dots .owl-dot:hover span, .testimonials-wrap-slides .owl-theme .owl-dots .owl-dot.active span {
  background: linear-gradient(30deg, #fd6d6d 13.39%, #ed3389 50%, #d837bf 86.61%);
}
.testimonials-wrap-slides .owl-theme .owl-dots .owl-dot.active span {
  width: 50px;
}

.overview-connections-area {
  background-color: #282525;
}
.overview-connections-area .section-title.text-start h2 {
  max-width: 100%;
  color: var(--whiteColor);
}
.overview-connections-area .section-title p {
  padding-left: 25px;
  color: var(--whiteColor);
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
}
.overview-connections-area .section-title .bottom-zero {
  margin-bottom: 0;
}
.overview-connections-area.bg-FAF8F8 {
  background-color: #faf8f8 !important;
  margin-top: -162px;
  padding-top: 255px;
}
.overview-connections-area.bg-FAF8F8 .section-title.text-start h2 {
  color: var(--blackColor) !important;
}
.overview-connections-area.bg-FAF8F8 .section-title p {
  color: var(--paragraphColor) !important;
}
.overview-connections-area.bg-FAF8F8 .overview-connections-card p {
  color: var(--paragraphColor) !important;
}

/*faq-css*/
.faq-area {
  position: relative;
  z-index: 1;
}

.faq-accordion accordion-group {
  margin-bottom: 15px;
  display: block;
}
.faq-accordion accordion-group:last-child {
  margin-bottom: 0;
}
.faq-accordion .panel {
  border-radius: 5px;
  background-color: var(--whiteColor);
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
}
.faq-accordion .panel-group .panel-heading .panel-title {
  margin-bottom: 0;
}
.faq-accordion .panel-group .panel-heading .panel-title a {
  display: block;
  box-shadow: unset;
  position: relative;
  color: var(--blackColor);
  padding: 17px 15px 17px 45px;
  font-size: 16px;
  font-weight: 600;
}
.faq-accordion .panel-group .panel-heading .panel-title a::before {
  top: 50%;
  left: 13px;
  content: "\ea4a";
  position: absolute;
  transform: translateY(-50%);
  font-size: 25px;
  font-family: "boxicons";
}
.faq-accordion .panel-group .panel-collapse {
  padding-bottom: 20px;
  padding-right: 25px;
  padding-left: 45px;
}
.faq-accordion .panel-group .panel-collapse p {
  line-height: 1.8;
}
.faq-accordion .panel-group .dropup .panel-heading .panel-title a::before {
  content: "\ea57";
}
.faq-accordion .panel-group .collapse.in {
  display: block;
}

@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }

  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pt-100 {
    padding-top: 60px;
  }

  .pb-100 {
    padding-bottom: 60px;
  }

  .ptb-70 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pt-70 {
    padding-top: 30px;
  }

  .pb-70 {
    padding-bottom: 30px;
  }

  p {
    font-size: 14px;
  }

  .section-title {
    margin-bottom: 35px;
  }
  .section-title .sub-title {
    margin-bottom: 8px;
    font-size: 13.5px;
  }
  .section-title h2 {
    font-size: 27px;
  }
  .section-title p {
    max-width: 100%;
  }
  .section-title.text-start {
    text-align: center !important;
  }
  .section-title.text-start h2 {
    max-width: 100%;
  }
  .section-title.text-start .default-btn {
    display: none;
  }

  .default-btn {
    padding: 12px 20px 11px 50px;
    font-size: 13px;
  }
  .default-btn .icon-arrow {
    top: 10px;
    font-size: 22px;
  }

  .mission-content .owl-theme .owl-nav [class*=owl-] {
    font-size: 25px;
    width: 35px;
    height: 35px;
    line-height: 41px;
    left: -30px;
  }
  .mission-content .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -30px;
  }

  .funfacts-inner {
    border-radius: 10px;
    padding: 25px 20px 0;
  }
  .funfacts-inner .col-lg-3 {
    border-right: none;
  }

  .products-details-tab .tabs-container {
    padding: 20px;
  }
  .products-details-tab .tabs-container .products-details-tab-content .additional-information li span {
    display: block;
    width: 100%;
    margin-bottom: 3px;
  }
  .products-details-tab .tabs-container .products-details-tab-content h3 {
    font-size: 18px;
  }
  .products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-title .default-btn {
    position: relative;
    margin-top: 15px;
  }
  .products-details-tab .tabs-container .products-details-tab-content .product-review-form .review-comments .review-item {
    margin-top: 30px;
    position: relative;
    padding-right: 0;
    border-top: 1px dashed #eeeeee;
    padding-top: 30px;
  }

  .instructor-area {
    overflow: hidden;
  }

  .faq-accordion-tab .nav-tabset {
    margin-bottom: 40px;
  }
  .faq-accordion-tab .nav-tabset li {
    margin-bottom: 10px;
  }
  .faq-accordion-tab .nav-tabset li span {
    padding: 10px;
    font-size: 14px;
  }

  .feedback-slides .owl-theme {
    margin-bottom: 0;
  }
  .feedback-slides .owl-theme .single-feedback-item {
    margin-bottom: 30px;
  }
  .feedback-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .feedback-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .nav-tabset {
    text-align: center;
    margin-top: 0;
    margin-bottom: 22px;
  }
  .nav-tabset .nav-tab {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .nav-tabset .nav-tab:first-child {
    margin-left: 0;
  }
  .nav-tabset .nav-tab:last-child {
    margin-right: 0;
  }

  .courses-list-tab .nav-tabset {
    margin-bottom: 35px;
    padding-left: 0;
    padding-right: 0;
  }
  .courses-list-tab .nav-tabset .nav-tab {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .courses-list-tab .nav-tabset .nav-tab span {
    display: block;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .blog-slides .owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .blog-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .blog-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .courses-categories-slides .owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .courses-categories-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .courses-categories-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .home-slides .owl-theme .owl-nav [class*=owl-] {
    left: 10px;
    font-size: 27px;
    width: 35px;
    height: 35px;
  }
  .home-slides .owl-theme .owl-nav [class*=owl-] i {
    top: 55%;
    transform: translateY(-55%);
  }
  .home-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: 10px;
  }

  .instructor-slides .owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .instructor-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .instructor-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .feedback-form-area {
    margin-top: -10px;
  }

  .divider {
    bottom: -1px;
  }

  .courses-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .courses-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }
  .courses-slides .owl-theme .owl-dots {
    display: none !important;
  }

  .testimonials-slides .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0;
  }

  .courses-topbar {
    text-align: center;
  }
  .courses-topbar .topbar-result-count p {
    font-size: 15px;
  }
  .courses-topbar .topbar-ordering {
    margin-top: 5px;
    margin-bottom: 7px;
  }

  .page-title-area {
    padding-top: 265px;
    padding-bottom: 90px;
  }
  .page-title-area.page-title-style-two {
    padding-top: 270px;
    padding-bottom: 100px;
  }
  .page-title-area.page-title-style-three {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .page-title-content h2 {
    font-size: 30px;
  }

  blockquote,
.blockquote {
    padding: 20px !important;
  }
  blockquote p,
.blockquote p {
    font-size: 17px !important;
  }

  .prev-link-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }
  .prev-link-wrapper .prev-title {
    font-size: 15px;
  }
  .prev-link-wrapper .meta-wrapper {
    font-size: 13px;
  }

  .next-link-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    margin-top: 30px;
  }
  .next-link-wrapper .next-title {
    font-size: 15px;
  }
  .next-link-wrapper .meta-wrapper {
    font-size: 13px;
  }

  .widget-area {
    margin-top: 40px;
  }
  .widget-area .widget {
    margin-bottom: 30px;
  }
  .widget-area .widget:last-child {
    margin-bottom: 0;
  }
  .widget-area .widget .widget-title {
    font-size: 17px;
  }
  .widget-area .widget_ednuv_posts_thumb .item .info {
    margin-top: 0;
  }
  .widget-area .widget_ednuv_posts_thumb .item .info time {
    margin-top: 0;
  }
  .widget-area .widget_ednuv_posts_thumb .item .info .title {
    font-size: 14px;
  }
  .widget-area .widget_categories ul li {
    font-size: 13px;
  }
  .widget-area .widget_categories ul li::before {
    top: 6px;
  }
  .widget-area .tagcloud a {
    padding: 6px 15px;
    font-size: 13px !important;
  }

  .pagination-area {
    margin-top: 0;
  }

  .overview-connections-area .section-title.text-start {
    text-align: start !important;
  }
  .overview-connections-area .section-title.text-start h2 {
    margin-bottom: 15px;
  }
  .overview-connections-area .section-title p {
    padding-left: 0;
  }
  .overview-connections-area.bg-FAF8F8 {
    padding-top: 230px;
  }

  .business-shape1,
.business-shape2,
.business-shape3,
.business-shape4,
.business-shape5,
.business-shape6,
.business-shape7,
.business-shape8,
.business-shape9,
.business-shape10 {
    display: none;
  }

  .health-coaching-shape1,
.health-coaching-shape2,
.health-coaching-shape3,
.health-coaching-shape4,
.health-coaching-shape5,
.health-coaching-shape6,
.health-coaching-shape7,
.health-coaching-shape8,
.health-coaching-shape9 {
    display: none;
  }

  .lang-shape1,
.lang-shape2,
.lang-shape3,
.lang-shape4,
.lang-shape5 {
    display: none;
  }

  .gym-shape2 {
    right: 0;
  }

  .faq-accordion .panel-group .panel-heading .panel-title a {
    padding: 17px 15px 17px 45px;
    font-size: 15px;
  }

  .feedback-slides-two .owl-theme .owl-nav.disabled + .owl-dots {
    position: relative;
    transform: unset;
    margin-top: 25px;
    right: 0;
    top: 0;
  }
  .feedback-slides-two .owl-theme .owl-dots .owl-dot {
    display: inline-block;
  }
  .feedback-slides-two .owl-theme .owl-dots .owl-dot span {
    margin: 0 3px;
  }

  .health-coaching-banner-slides .owl-theme .owl-nav [class*=owl-] {
    left: 15px;
    top: auto;
    transform: translateY(0);
    bottom: 20px;
  }
  .health-coaching-banner-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: 15px;
  }

  .feedback-area-two .feedback-slides .owl-theme .single-feedback-box {
    max-width: 100%;
  }
  .feedback-area-two .feedback-slides .owl-theme .owl-nav {
    margin-top: 20px;
  }
  .feedback-area-two .feedback-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 30px;
    margin: 0;
  }

  .gym-home-slides .owl-theme .owl-nav {
    display: none;
  }

  .testimonials-wrap-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .page-title-area {
    padding-top: 240px;
  }

  .courses-topbar .topbar-result-count {
    margin-bottom: 10px;
  }
  .courses-topbar .topbar-ordering {
    margin-top: 0;
    margin-bottom: 0;
  }

  .testimonials-wrap-area .container-fluid {
    max-width: 540px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pt-100 {
    padding-top: 80px;
  }

  .pb-100 {
    padding-bottom: 80px;
  }

  .ptb-70 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pt-70 {
    padding-top: 50px;
  }

  .pb-70 {
    padding-bottom: 50px;
  }

  .section-title {
    margin-bottom: 50px;
  }
  .section-title.text-start {
    text-align: center !important;
  }
  .section-title.text-start h2 {
    max-width: 100%;
  }
  .section-title.text-start .default-btn {
    display: none;
  }

  .divider {
    bottom: -1px;
  }

  .nav-tabset {
    text-align: center;
    margin-top: -15px;
    margin-bottom: 20px;
  }
  .nav-tabset .nav-tab {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .nav-tabset .nav-tab:first-child {
    margin-left: 0;
  }
  .nav-tabset .nav-tab:last-child {
    margin-right: 0;
  }

  .courses-list-tab .nav-tabset .nav-tab {
    margin-bottom: 0;
  }

  .courses-categories-slides .owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .courses-categories-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .courses-categories-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .funfacts-inner {
    padding: 30px;
  }

  .courses-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .courses-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }
  .courses-slides .owl-theme .owl-dots {
    display: none !important;
  }

  .instructor-slides .owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .instructor-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .instructor-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .feedback-form-area {
    margin-top: 0;
  }

  .feedback-slides .owl-theme .owl-nav [class*=owl-] {
    left: 0 !important;
    top: auto;
    transform: unset;
    bottom: 40px;
    opacity: 1;
    visibility: visible;
  }
  .feedback-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: 75px !important;
    right: auto;
  }

  .blog-slides .owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .blog-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .blog-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .home-slides .owl-theme .owl-nav [class*=owl-] {
    top: auto;
    transform: unset;
    bottom: 20px;
    z-index: 2;
  }

  .widget-area {
    margin-top: 40px;
  }

  .page-title-area {
    padding-top: 300px;
    padding-bottom: 110px;
  }

  .business-shape1,
.business-shape2,
.business-shape3,
.business-shape4,
.business-shape5,
.business-shape6,
.business-shape7,
.business-shape8,
.business-shape9,
.business-shape10 {
    display: none;
  }

  .health-coaching-shape1,
.health-coaching-shape2,
.health-coaching-shape3,
.health-coaching-shape4,
.health-coaching-shape5,
.health-coaching-shape6,
.health-coaching-shape7,
.health-coaching-shape8,
.health-coaching-shape9 {
    display: none;
  }

  .lang-shape1,
.lang-shape2,
.lang-shape3,
.lang-shape4,
.lang-shape5 {
    display: none;
  }

  .gym-shape2 {
    right: 0;
  }

  .health-coaching-banner-slides .owl-theme .owl-nav [class*=owl-] {
    left: 15px;
  }
  .health-coaching-banner-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: 15px;
  }

  .feedback-area-two .feedback-slides .owl-theme .single-feedback-box {
    max-width: 100%;
  }
  .feedback-area-two .feedback-slides .owl-theme .owl-nav [class*=owl-] {
    bottom: 0;
    left: 0 !important;
  }
  .feedback-area-two .feedback-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .overview-connections-area.bg-FAF8F8 {
    padding-top: 240px;
  }
  .overview-connections-area .section-title.text-start {
    text-align: start !important;
  }
  .overview-connections-area .section-title.text-start h2 {
    margin-bottom: 15px;
  }
  .overview-connections-area .section-title p {
    padding-left: 0;
  }

  .feedback-area-two .feedback-slides .owl-theme .single-feedback-box {
    max-width: 100%;
  }
  .feedback-area-two .feedback-slides .owl-theme .owl-nav [class*=owl-] {
    bottom: 0;
    left: 0 !important;
  }
  .feedback-area-two .feedback-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .gym-home-slides .owl-theme .owl-nav {
    display: none;
  }

  .testimonials-wrap-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 720px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-categories-slides .owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .courses-categories-slides .owl-theme .owl-nav [class*=owl-] {
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .courses-categories-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: 0 !important;
    right: 0 !important;
  }

  .blog-slides .owl-theme .owl-nav {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .blog-slides .owl-theme .owl-nav [class*=owl-] {
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .blog-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: 0 !important;
    right: 0 !important;
  }

  .instructor-slides .owl-theme .owl-nav {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .instructor-slides .owl-theme .owl-nav [class*=owl-] {
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .instructor-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: 0 !important;
    right: 0 !important;
  }

  .courses-slides .owl-theme .owl-nav {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .courses-slides .owl-theme .owl-nav [class*=owl-] {
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .courses-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: 0 !important;
    right: 0 !important;
  }
  .courses-slides .owl-theme .owl-dots {
    display: none !important;
  }

  .business-shape1,
.business-shape2,
.business-shape3,
.business-shape4,
.business-shape5,
.business-shape6,
.business-shape7,
.business-shape8,
.business-shape9,
.business-shape10 {
    display: none;
  }

  .health-coaching-shape1,
.health-coaching-shape2,
.health-coaching-shape3,
.health-coaching-shape4,
.health-coaching-shape5,
.health-coaching-shape6,
.health-coaching-shape7,
.health-coaching-shape8,
.health-coaching-shape9 {
    display: none;
  }

  .lang-shape1,
.lang-shape2,
.lang-shape4,
.lang-shape5 {
    display: none;
  }

  .health-coaching-banner-slides .owl-theme .owl-nav [class*=owl-] {
    left: 20px;
  }
  .health-coaching-banner-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: 20px;
  }

  .feedback-area-two .feedback-slides .owl-theme .single-feedback-box {
    max-width: 700px;
  }

  .gym-home-slides .owl-theme .owl-nav {
    display: none;
  }

  .testimonials-wrap-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 960px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1275px) {
  .courses-categories-slides .owl-theme .owl-nav [class*=owl-] {
    left: -30px;
  }
  .courses-categories-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -30px;
  }
  .courses-categories-slides .owl-theme:hover .owl-nav [class*=owl-], .courses-categories-slides .owl-theme:focus .owl-nav [class*=owl-] {
    left: -40px;
  }
  .courses-categories-slides .owl-theme:hover .owl-nav [class*=owl-].owl-next, .courses-categories-slides .owl-theme:focus .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -40px;
  }

  .courses-slides .owl-theme .owl-nav [class*=owl-] {
    left: -30px;
  }
  .courses-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -30px;
  }
  .courses-slides .owl-theme:hover .owl-nav [class*=owl-], .courses-slides .owl-theme:focus .owl-nav [class*=owl-] {
    left: -40px;
  }
  .courses-slides .owl-theme:hover .owl-nav [class*=owl-].owl-next, .courses-slides .owl-theme:focus .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -40px;
  }

  .blog-slides .owl-theme .owl-nav [class*=owl-] {
    left: -30px;
  }
  .blog-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -30px;
  }
  .blog-slides .owl-theme:hover .owl-nav [class*=owl-], .blog-slides .owl-theme:focus .owl-nav [class*=owl-] {
    left: -40px;
  }
  .blog-slides .owl-theme:hover .owl-nav [class*=owl-].owl-next, .blog-slides .owl-theme:focus .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -40px;
  }

  .instructor-slides .owl-theme .owl-nav [class*=owl-] {
    left: -30px;
  }
  .instructor-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -30px;
  }
  .instructor-slides .owl-theme:hover .owl-nav [class*=owl-], .instructor-slides .owl-theme:focus .owl-nav [class*=owl-] {
    left: -40px;
  }
  .instructor-slides .owl-theme:hover .owl-nav [class*=owl-].owl-next, .instructor-slides .owl-theme:focus .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -40px;
  }

  .business-shape1 img {
    max-width: 620px;
  }

  .health-coaching-shape1,
.health-coaching-shape2 {
    display: none;
  }

  .lang-shape1 {
    display: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonials-wrap-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
}
@media only screen and (min-width: 1550px) {
  .business-shape1 {
    right: 14%;
  }

  .business-shape8 img {
    max-width: 100%;
  }

  .health-coaching-shape4 {
    right: 9.5%;
  }
  .health-coaching-shape4 img {
    max-width: 100%;
  }

  .health-coaching-shape7 img {
    max-width: 100%;
  }

  .health-coaching-shape8 img {
    max-width: 100%;
  }

  .health-coaching-shape9 {
    right: 12%;
    top: 25%;
  }
  .health-coaching-shape9 img {
    max-width: 100%;
  }

  .lang-shape1 img {
    max-width: 100%;
  }

  .lang-shape2 img {
    max-width: 100%;
  }

  .lang-shape3 img {
    max-width: 100%;
  }

  .lang-shape4 {
    right: 8%;
    top: 30%;
  }

  .lang-shape5 {
    left: 3%;
  }
  .lang-shape5 img {
    max-width: 100%;
  }

  .gym-home-slides .owl-theme .owl-nav [class*=owl-] {
    left: 70px;
  }
  .gym-home-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: 70px;
  }

  .testimonials-wrap-area .container-fluid {
    max-width: 2000px;
  }
}